declare global {
  interface Window {
    pendo: pendo.Pendo & { setGuidesDisabled?: (val: boolean) => void }
  }
}

export const trackPendoEvent = (event: string, properties = {}) => {
  if (window.pendo && typeof window.pendo.track === 'function') {
    window.pendo.track(event, properties)
  }
}

export const isPendoReady = () => {
  let isReady = false
  if (window.pendo && typeof window.pendo.isReady === 'function') {
    isReady = window.pendo.isReady()
  }
  return isReady
}

export const disablePendoGuides = () => {
  if (
    window.pendo &&
    typeof window.pendo.setGuidesDisabled === 'function' &&
    isPendoReady()
  ) {
    window.pendo.setGuidesDisabled(true)
    window.pendo.stopGuides()
  }
}
