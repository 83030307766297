import { Component, ErrorInfo, ReactNode } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { TFunction } from 'i18next'
import styled from 'styled-components'

import { TEXT_STYLES } from '../constants/text_styles'
import { CONTACT_SUPPORT } from '../constants/segment'

import { getPathData, sendCtaClickEvent } from '../helpers/segment'
import { logException } from '../services/sentry'

interface Props {
  children: ReactNode
  t: TFunction
}

interface State {
  error?: Error
  errorInfo?: ErrorInfo
  hasError: boolean
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;

  .column {
    margin-top: 177px;
    flex-direction: row;
    flex-basis: 50%;

    * {
      margin: 0 auto;
    }
  }

  h1 {
    ${TEXT_STYLES.Coaching1}
    text-align: left;
    max-width: 281px;
    margin-bottom: 20px !important;
  }

  p {
    ${TEXT_STYLES.BodyBlack}
    max-width: 281px;
  }

  a {
    ${TEXT_STYLES.H3Orange}
    text-decoration: underline;
    cursor: pointer;
  }

  .stack-trace {
    white-space: pre-wrap;
    text-align: left;
    width: max-content;
  }
`

const Logo = styled.img.attrs({
  src: 'https://dh2wkbcb9yv3l.cloudfront.net/images/logos/js-logo-text.svg',
  alt: 'jungle scout logo',
})`
  position: absolute;
  top: 20px;
  left: 20px;

  width: 164px;
  height: 25px;
  object-fit: contain;
`

const ErrorBoundaryContent = () => {
  const contactSupportCallback = () => {
    const page = window.location.hash.substr(1)
    const location = getPathData(page)?.name || ''

    sendCtaClickEvent({
      destination: 'mail',
      text: CONTACT_SUPPORT,
      appType: 'default',
      location,
    })
  }

  return (
    <p>
      <Trans i18nKey="generic:Error.refreshOrContact">
        <a href=".">Refresh</a> Or{' '}
        <a
          href="mailto:support@junglescout.com"
          onClick={contactSupportCallback}>
          Contact Support
        </a>
      </Trans>
    </p>
  )
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logException(error, {
      stackTrace: errorInfo.componentStack,
    })

    this.setState({
      error,
      errorInfo,
    })
  }

  renderStackTrace() {
    if (process.env.REACT_APP_JUNGLE_ENV === 'production') {
      return (
        <img
          src="https://dh2wkbcb9yv3l.cloudfront.net/images/error-page.png"
          alt="error"
        />
      )
    }

    const { error, errorInfo } = this.state

    if (!error) {
      return <p>Failed to get stack trace</p>
    }

    return (
      <>
        <h3>{error?.toString()}</h3>
        <div className="stack-trace">
          {(errorInfo ? errorInfo.componentStack?.trim() : error?.stack) ||
            'Stack trace unavailable'}
        </div>
        <br />
        <br />
        <i>This stack trace is not visible in production</i>
      </>
    )
  }

  render(): ReactNode {
    const { hasError } = this.state
    const { children, t } = this.props

    if (!hasError) {
      return children
    }

    return (
      <>
        <Logo />
        <Wrapper>
          <div className="column">
            <h1>
              {t(
                'generic:Error.appCrashed',
                'Oops! Something seems to have gone wrong.',
              )}
            </h1>
            <ErrorBoundaryContent />
          </div>
          <div className="column">{this.renderStackTrace()}</div>
        </Wrapper>
      </>
    )
  }
}

const WrappedComponent = withTranslation('generic')(ErrorBoundary)
export { WrappedComponent as ErrorBoundary }
