import { ReactElement } from 'react'
import styled, {
  withTheme,
  css,
  keyframes,
  DefaultTheme,
  IColor,
} from 'styled-components'

export interface Props {
  className?: string
  color: string
  disableAnimation?: boolean
  height?: string
  size?: string
  theme: DefaultTheme
}

interface SpinningIconProps {
  disableAnimation: boolean
  height?: string
  size?: string
}

const rotate360 = keyframes`
to {
  transform: rotate(360deg);
}
`

const animation = (disable: boolean) => {
  return disable
    ? 'none'
    : css`
        ${rotate360} 1.2s linear infinite
      `
}

const SpinningIcon = styled.div<SpinningIconProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.height || props.size};
  animation: ${(props) => animation(props.disableAnimation)};
`

function LoadingIcon({
  disableAnimation = false,
  className,
  size,
  height,
  theme,
  color,
}: Props): ReactElement {
  const iconColor: string =
    theme.colors[color as keyof IColor] || theme.colors.white

  return (
    <SpinningIcon
      className={className}
      size={size}
      height={height}
      disableAnimation={disableAnimation}>
      <svg width="100%" height="100%" viewBox="0 0 45 45">
        <title>loading</title>
        <path
          fill={iconColor}
          fillRule="nonzero"
          stroke={iconColor}
          d="M44 22.25C44 10.52 34.351 1 22.5 1a1.229 1.229 0 1 0 0 2.457c10.54 0 19.043 8.408 19.043 18.794 0 7.346-4.237 13.736-10.443 16.835v-6.143a1.229 1.229 0 1 0-2.457 0v8.6c0 .679.55 1.229 1.228 1.229h8.6a1.229 1.229 0 1 0 0-2.458h-4.53C39.996 36.541 44 29.837 44 22.251zM23.748 42.638a1.229 1.229 0 0 0-1.248-1.094c-10.54 0-19.043-8.408-19.043-18.793 0-7.356 4.241-13.76 10.462-16.855v6.162a1.229 1.229 0 1 0 2.457 0v-8.6c0-.678-.55-1.228-1.228-1.228h-8.6a1.229 1.229 0 1 0 0 2.457h4.53C5.017 8.459 1 15.162 1 22.75 1 34.48 10.649 44 22.5 44a1.229 1.229 0 0 0 1.248-1.363z"
        />
      </svg>
    </SpinningIcon>
  )
}

const wrappedComponent = withTheme(LoadingIcon)

export { wrappedComponent as LoadingIcon }
