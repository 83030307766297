import { createContext, FunctionComponent } from 'react'

enum AppTypes {
  cobalt = 'cobalt',
  orange = 'default',
}

export interface ResetTokenInterface {
  token: string
  appType: AppTypes
}

export const ResetTokenContext = createContext<ResetTokenInterface>({
  token: '',
  appType: AppTypes.orange,
})

const searchParams = new URLSearchParams(window.location.search)
const token = searchParams.get('reset_password_token') || ''
const appType = searchParams.get('app_type') as AppTypes

if (token) {
  window.history.replaceState({}, document.title, window.location.pathname)
}

export const resetContext: ResetTokenInterface = {
  token,
  appType,
}

export const ResetTokenProvider: FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => (
  <ResetTokenContext.Provider value={resetContext}>
    {children}
  </ResetTokenContext.Provider>
)
