export interface JwtType {
  app_type: string
  aud: string
  auth_token: string
  exp: number
  iat: number
  iss: string
}

function isDeadObjectException(e: Error) {
  return e instanceof TypeError && e.message === "can't access dead object"
}

const oneMonthLater = (): string => {
  const d = new Date()
  const thisYear = d.getUTCFullYear()
  const todayDay = d.getUTCDate()
  const nextMonth = d.getUTCMonth() + 1

  return new Date(thisYear, nextMonth, todayDay).toUTCString()
}

const currentDomain = () => {
  return `junglescout.${
    window.location.hostname.endsWith('.cn') ? 'cn' : 'com'
  }`
}

const addCookieDomain =
  process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test'

export const getCookie = (name: string) => {
  try {
    const cookiePairs = document.cookie.split('; ')
    const filteredPairs = cookiePairs.filter((element) =>
      element.startsWith(`${name}=`),
    )

    if (filteredPairs.length > 0) {
      return filteredPairs[0].split('=')[1]
    }

    return null
  } catch (e) {
    if (isDeadObjectException(e as Error)) {
      return null
    }

    throw e
  }
}
export interface Options {
  domain: string
  expires: Date
}

export const daysFromNow = (days: number): Date => {
  const now = new Date()

  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + days)
}

export const setCookie = (prop: string, value: string, opts: Options) => {
  const { domain, expires } = opts
  const expiresAt = expires.toUTCString() || ''
  let cookie = `${prop}=${value};expires=${expiresAt};path=/`

  if (domain) {
    cookie = `${cookie};domain=${domain}`
  }

  document.cookie = cookie
}

export const saveAuthToken = (token: string): void => {
  const envCookieName = process.env.REACT_APP_AUTH_TOKEN_COOKIE || ''
  const cookieName = envCookieName.replace(/(\r\n|\n|\r)/gm, '')
  const cookies = [
    `${cookieName}=${token};expires=${oneMonthLater()};path=/`,
    // STYXKEY_ is a prefix required for the marketing website's PHP server to read it.
    `STYXKEY_${cookieName}=${token};expires=${oneMonthLater()};path=/`,
  ]

  cookies.forEach((cookie) => {
    document.cookie = addCookieDomain
      ? `${cookie};domain=${currentDomain()}`
      : cookie
  })
}

export const parseAuthTokenJwt = (token: string): JwtType | null => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as JwtType
  } catch (e) {
    return null
  }
}

export const isDateLessThan60DaysOld = (dateString: string | null): boolean => {
  if (!dateString || typeof dateString !== 'string') {
    return false
  }
  const date = new Date(dateString)
  const now = new Date()

  const differenceInMillis = now.getTime() - date.getTime()

  const sixtyDaysInMillis = 60 * 24 * 60 * 60 * 1000
  return differenceInMillis < sixtyDaysInMillis
}
