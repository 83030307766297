import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import deResource from './locales/de-de/index'
import enResource from './locales/en-us/index'
import esResource from './locales/es-es/index'
import frResource from './locales/fr-fr/index'
import hiResource from './locales/hi-in/index'
import itResource from './locales/it-it/index'
import jaResource from './locales/ja-jp/index'
import zhResource from './locales/zh-hant/index'

/* eslint-disable  @typescript-eslint/no-floating-promises */
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources: {
      de: deResource,
      en: enResource,
      es: esResource,
      fr: frResource,
      hi: hiResource,
      it: itResource,
      ja: jaResource,
      zh: zhResource,
    }, // add resources for new languages here after translations are added by Lokalise
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnEmptyString: false,
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'p'],
      useSuspense: false,
    },
  })

export default i18n
