import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'

import './index.css'
import App from './App'

import { checkRedirection } from './helpers/redirection'
import './i18n'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.SENTRY_RELEASE_ID || '1.0.0',
  environment: process.env.REACT_APP_JUNGLE_ENV,
})

const queryClient = new QueryClient()

const init = () => {
  const rootElement = document.getElementById('root')
  if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </React.StrictMode>,
    )
  }
}

const willRedirect = checkRedirection()

if (!willRedirect) {
  init()
}
