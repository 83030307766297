/* eslint-disable */

import { SEGMENT_PAGE_TRACK_ROUTES_MAPPING } from '../constants/routes'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
    FS?: any
    fsIsReady: boolean
  }
}

interface EventData {
  errorTitle?: string
  message?: string
  tab?: string
  destination?: string
  location?: string
  pageUrl?: string
  product?: string
  text?: string
  type?: string
}

interface ClickEvent {
  appType: string
  destination: string
  location: string
  text: string
}

const filterIdentifyTraits = (params: any = {}) => {
  const allowedAttributes = [
    'id',
    'email',
    'languageBrowser',
    'languageApp',
    'mostRecentFullStorySession',
  ]

  // return the object with allowed attributes only
  return allowedAttributes.reduce((o, key) => {
    if (key in params) {
      Object.assign(o, { [key]: params[key] })
    }

    return o
  }, {})
}

export const sendSegmentTrackEvent = (
  eventName: string,
  extraProps: EventData,
) => {
  window.analytics?.track(eventName, extraProps)
}

const sendSegmentIdentity = (userId: string, extraProps: any = {}) => {
  if (!userId) {
    return
  }

  const getFullStorySessionUrl = window?.FS?.getCurrentSessionURL

  window.analytics?.identify(
    userId,
    filterIdentifyTraits({
      id: userId,
      ...extraProps,
      languageBrowser: window.navigator?.language,
      mostRecentFullStorySession:
        typeof getFullStorySessionUrl === 'function'
          ? getFullStorySessionUrl()
          : undefined,
    }),
  )
}

export const getProductForSegment = (appType: string) =>
  appType === 'default'
    ? 'Jungle Scout'
    : appType && appType.charAt(0)?.toUpperCase() + appType.slice(1)

export const sendCtaClickEvent = ({
  destination,
  text,
  appType,
  location,
}: ClickEvent) => {
  // Just a safety check, because if someone sends something like an event
  //  or some types of React components it can cause a circular dependency
  //  and crash the action
  const sanitizedText = typeof text === 'string' ? text : 'CTA Clicked'

  const segmentEventData = {
    destination,
    type: 'button',
    text: sanitizedText,
    pageUrl: window.location.href,
    product: getProductForSegment(appType),
    location,
  }

  sendSegmentTrackEvent('CTA Clicked', segmentEventData)
}

export const getPathData = (path: string) => {
  if (SEGMENT_PAGE_TRACK_ROUTES_MAPPING?.[path]) {
    return SEGMENT_PAGE_TRACK_ROUTES_MAPPING[path]
  }

  // try to find the best match based on how similar paths are
  // filter by RegExp hit and sort by length
  const result = Object.keys(SEGMENT_PAGE_TRACK_ROUTES_MAPPING)
    .filter((_path) => new RegExp(_path).test(path) === true)
    .sort((a, b) => b.length - a.length)

  if (result.length) {
    return SEGMENT_PAGE_TRACK_ROUTES_MAPPING[result[0]]
  }

  return null
}

export const sendSegmentPageEvent = (
  pathname: string,
  appType = 'default',
  prevPath: string | undefined = undefined,
): void => {
  const pathData = getPathData(pathname)

  if (pathData) {
    const { search } = new URL(document.URL)

    window.analytics?.page({
      product: getProductForSegment(appType),
      pageName: pathData.name,
      category: pathData.category,
      title: document.title,
      url: document.URL,
      referrer: prevPath
        ? `${document.location.origin}${prevPath}`
        : document.referrer,
      path: pathname,
      search,
    })
  }
}

export const sendSegmentTrackAndIdentifyEvents = (
  eventName: string,
  trackProps: any,
  userId: string,
  identifyProps: any = {},
) => {
  sendSegmentIdentity(userId, identifyProps)
  sendSegmentTrackEvent(eventName, trackProps)
}
