interface SegmentRouteMapping {
  name: string
  category: string
}

export const LOGIN_URL = '/'
export const RESET_PASSWORD_URL = '/reset-password'
export const CN_PRICING_PAGE_URL = 'https://www.junglescout.cn/pricing'
export const CN_UPGRADE_PLAN_PAGE =
  'https://www.junglescout.cn/upgrade-plan-page'
export const SELECT_REGION_PAGE_URL =
  'https://www.junglescout.com/sign-up/select-region-app/'

export const SEGMENT_PAGE_TRACK_ROUTES_MAPPING: {
  [key in string]: SegmentRouteMapping
} = {
  [LOGIN_URL]: {
    name: 'Login Page',
    category: 'Login',
  },
  [RESET_PASSWORD_URL]: {
    name: 'Reset Password Page',
    category: 'Reset Password',
  },
}
