import { CSSProperties } from 'react'
import NotificationSystem from 'react-notification-system'

import { COLORS } from './colors'

import NotificationErrorIcon from '../svgs/notification-error.svg'
import NotificationInfoIcon from '../svgs/notification-info.svg'
import NotificationSuccessIcon from '../svgs/notification-success.svg'
import CircleCloseIcon from '../svgs/circle-close.svg'

const boxShadow = `0 2px 6px 0 ${COLORS.blackOpacity20}`

const h4: CSSProperties = {
  fontSize: '13px',
  fontWeight: 500,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.38,
  letterSpacing: 'normal',
  color: COLORS.grey900,
}

export const NOTIFICATION_STYLE: NotificationSystem.Style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: '10px 5px 5px 0',
      backgroundColor: COLORS.white,
      boxShadow,
      WebkitBoxShadow: boxShadow,
      MozBoxShadow: boxShadow,
      color: COLORS.grey900,
      padding: '10px 10px 10px 45px',
      fontSize: '12px',
      borderWidth: '0 0 0 5px',
      borderStyle: 'solid',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '15px 10px',
    },
    success: {
      borderColor: COLORS.green700,
      backgroundImage: `url(${NotificationSuccessIcon})`,
    },
    error: {
      borderColor: COLORS.red500,
      backgroundImage: `url("${NotificationErrorIcon}")`,
    },
    info: {
      borderColor: COLORS.grey700,
      backgroundImage: `url("${NotificationInfoIcon}")`,
    },
  },
  Title: {
    DefaultStyle: h4,
  },
  Dismiss: {
    DefaultStyle: {
      content: `url("${CircleCloseIcon}")`,
      backgroundColor: COLORS.transparent,
      color: COLORS.transparent,
      top: '10px',
      right: '10px',
      width: '15px',
      height: '15px',
    },
  },
  Action: {
    DefaultStyle: {
      cursor: 'pointer',
    },
    success: {
      border: `1px solid ${COLORS.grey900}`,
      backgroundColor: COLORS.white,
      color: COLORS.grey900,
      fontSize: '11px',
      fontFamily: '"Inter", sans-serif',
      fontWeight: 500,
    },
    error: {
      backgroundColor: COLORS.red500,
      color: COLORS.white,
    },
    info: {
      backgroundColor: COLORS.grey700,
      color: COLORS.white,
    },
  },
}
