import { useEffect, useRef, useState } from 'react'
import { isPendoReady, disablePendoGuides } from '../services/pendo'

export const usePendo = (timeout = 10_000) => {
  const [pendoIsReady, setPendoIsReady] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const intervalRef = useRef<number>()

  useEffect(() => {
    if (!pendoIsReady && !intervalRef.current) {
      intervalRef.current = window.setInterval(() => {
        setPendoIsReady(() => isPendoReady())
        setElapsedTime((et) => et + 1000)
      }, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (elapsedTime >= timeout && intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }, [elapsedTime, intervalRef.current])

  return { disablePendoGuides, isPendoReady: isPendoReady() }
}
