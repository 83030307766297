import generic from './generic.json'
import login from './login.json'
import reset_password from './reset_password.json'
import password_registration from './password_registration.json'
import pre_checkout from './pre_checkout.json'

export default {
  generic,
  login,
  reset_password,
  password_registration,
  pre_checkout,
}
