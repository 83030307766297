import { withScope, captureMessage, Severity } from '@sentry/react'
import { getCookie, parseAuthTokenJwt } from '../services/cookies'

interface RedirectionStatus {
  time: number
  count: number
}

export const localStorageKey = 'redirection_status'
export const maxRedirectionCount = 5
export const acceptableRedirectionInterval = 1000 // 1 second

const defaultRedirectionStatus: RedirectionStatus = { time: 0, count: 0 }

export const redirectToApp = (authToken: string): void => {
  const parsedToken = parseAuthTokenJwt(authToken)

  let targetPath = ''

  if (window.location.search.includes('amazon_callback_uri')) {
    targetPath = window.location.search
  } else {
    const searchParams = new URLSearchParams(window.location.search)
    const redirectRoute = `${searchParams.get('redirectRoute') || ''}${
      window.location.hash
    }`

    const redirectParams = new URL(
      `${window.location.origin}/${redirectRoute.replace(/^\//g, '')}`,
    )
    const redirectPathname = redirectParams.pathname.replace(/\/$/g, '')

    targetPath = `/#${redirectPathname}${redirectParams.search}`
  }

  const baseUrl =
    parsedToken?.app_type === 'cobalt'
      ? process.env.REACT_APP_CLIENT_COBALT || ''
      : process.env.REACT_APP_CLIENT_ORANGE || ''

  window.location.assign(baseUrl + targetPath)
}

const getRedirectionStatus = (): RedirectionStatus => {
  const redirectionStatusStr =
    localStorage.getItem(localStorageKey) ||
    JSON.stringify(defaultRedirectionStatus)

  try {
    return JSON.parse(redirectionStatusStr) as RedirectionStatus
  } catch (e) {
    return defaultRedirectionStatus
  }
}

const resetRedirectionStatus = (): void => {
  localStorage.removeItem(localStorageKey)
}

// Stop the redirection if there were too many redirects during a short period of time
export const checkRedirection = (): boolean => {
  const authToken = getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE || '')

  if (!authToken || window.location.href.includes('/register')) {
    resetRedirectionStatus()
    return false
  }

  const redirectionStatus = getRedirectionStatus()

  const { time: previousTime, count: previousCount } = redirectionStatus
  const count = previousCount + 1
  const now = Date.now()
  const timeSinceLastRedirection = !previousTime ? 0 : now - previousTime
  const isLastRedirectionTimeAcceptable =
    timeSinceLastRedirection > acceptableRedirectionInterval

  // Reset if the last redirect is in an acceptable interval
  if (isLastRedirectionTimeAcceptable) {
    resetRedirectionStatus()
  } else {
    localStorage.setItem(localStorageKey, JSON.stringify({ time: now, count }))
  }

  if (isLastRedirectionTimeAcceptable || count < maxRedirectionCount) {
    redirectToApp(authToken)
    return true
  }

  // Send a warning to Sentry
  if (count >= maxRedirectionCount) {
    withScope((scope) => {
      scope.setLevel(Severity.Warning)
      captureMessage('Too many redirects')
    })
  }

  return false
}
