/* eslint-disable */

import * as Sentry from '@sentry/react'
import { Extras } from '@sentry/types'

export function logExceptionNoConsole(
  exception: any,
  extras: Extras | undefined = undefined,
) {
  Sentry.withScope((scope) => {
    if (extras) {
      scope.setExtras(extras)
    }
    Sentry.captureException(exception)
  })
}

export function logException(
  exception: any,
  extras: Extras | undefined = undefined,
) {
  logExceptionNoConsole(exception, extras)

  /* eslint-disabe-next-line no-console */
  console.error(exception)
}
